
.ChooseImage{
    width: 100%;
    border: 1px solid #ced4da;
    font-size: 30px;
    cursor: pointer;
    border-radius: 0.25rem;
    color: #ced4da;
    overflow: auto;
    position: relative;
    text-align: center;
}
.ChooseImage.invalid{
    color: #f46a6a;
    border: 1px solid #f46a6a;

}
.ChooseImage div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}