.form-label{
  text-transform: capitalize;
}

.invalid>div {
  border-color: #f46a6a;
}

.invalid>div:hover {
  border-color: #f46a6a;
}

.btn-group-example .btn:before {
  display: none !important;
}

.table-responsive {
  min-height: 60vh;
  /* overflow: hidden visible !important; */
}

.table-responsive .table {
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {


  .table-responsive thead {
    display: none;
  }

  .table-responsive tbody,
  .table-responsive td,
  .table-responsive th,
  .table-responsive tr {
    display: block;
    width: 100% !important;
  }

}

@media (max-width: 767px) {
  .responsive-button-label {
    display: none;
  }
}

.loader {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #002d64;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

}

.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border-bottom: 4px solid #000;
  border-left: 4px solid transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.form-horizontal > div{
  text-align: left !important;
}
.form-horizontal input{
  text-align: left !important;
}

*{
  direction: ltr !important;
}